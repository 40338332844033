<template>
    <div>
        <el-upload
                class="avatar-uploader"
                :http-request="fnUploadRequest"
                :show-file-list="false"
                :on-exceed="beyondFile"
                :on-success="handleVideoSuccess"
                :before-upload="beforeUploadVideo">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
    </div>
</template>

<script>
    const code = 200
    const Util = require('@/assets/utils');
    export default {
        props:{
            path: String,
        },
        computed:{
          imageUrl:{
              get: function () {
                  return this.path
              },
              set: function (v)  {
                  this.$emit("success",v,this.keys)
              }
          }
        },
        data(){
            return {
            }
        },
        methods:{

            async fnUploadRequest (option) {
                let list = '/manage/'
                const filePath = await Util.upload(list, option.file)
                this.imageUrl = filePath
                this.$emit('input', filePath)
                console.log('success === ', filePath)
            },
            // 视频上传
            beforeUploadVideo (file) {
                //todo
                const isJPG = file.type === 'image/jpeg' || 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 4;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
            // 视频上传成功后
            handleVideoSuccess (response, file, fileList) {
                //todo
            },
            // 视频添加多个视频文件事件
            beyondFile (files, fileList) {
                //todo
            },

            onError(error){
                console.log('error === ', error)
            },
            handleUpload(op){// 自定义上传
                let list = '/manage/'
                console.log('自定义上传', op)
                // Util.upload(list, op.file)
                // this.imageUrl = 'https://cdn.sstatic.net/Img/teams/teams-illo-free-sidebar-promo.svg?v=47faa659a05e';
                // input 父组件可以使用v-model动态绑定
                // this.$emit('input', 'https://cdn.sstatic.net/Img/teams/teams-illo-free-sidebar-promo.svg?v=47faa659a05e')
            },
            handleAvatarSuccess(res, file) {
                if(res.code == code){
                    this.imageUrl = res.path;
                    // input 父组件可以使用v-model动态绑定
                    this.$emit('input', res.path)
                }
                console.log(res)
                // this.imageUrl = URL.createObjectURL(file.raw);
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg' || 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 4;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            }
        },
        watch:{
            path(newValue){

            }
        }
    }
</script>

<style scoped>
.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px;
    height: 178px;
}
.avatar-uploader:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    /* height: 178px; */
    display: block;
}
</style>