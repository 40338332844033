<template>
    <div class="home">
        <!-- search -->
        <div class="search">
            <el-form class="form" :inline="true" :model="query" ref="search">
                <el-form-item label="主题">
                    <el-input v-model="query.title" placeholder="请输入主题" :clearable="true"></el-input>
                </el-form-item>
                <el-form-item label="类型">
                    <el-select v-model="query.type" placeholder="请选择类型" :clearable="true">
                        <el-option label="回报社会" value="1"></el-option>
                        <el-option label="基金募集" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="展示状态">
                    <el-select v-model="query.display" placeholder="请选择展示状态" :clearable="true">
                        <el-option label="展示" value="true"></el-option>
                        <el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch" v-loading="loadingOne">查询</el-button>
                    <el-button type="primary" @click="handleAdd" style="margin-left: 40px;">新建</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- table -->
        <!-- 此处这么处理是因为：当弹框的时候，多选框会显示 -->
        <template v-if="!presentVisible"> 
            <div class="tablewrapper">
                <el-table ref="multipleTable" :data="list.list" tooltip-effect="dark" @selection-change="handleSelectionChange" stripe>
                    <el-table-column type="selection" width="50"></el-table-column>
                    <el-table-column type="index" width="40" label="#"></el-table-column>
                    <el-table-column prop="title" label="主题" min-width="120" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="typeZh" label="类型" min-width="60" align="center"></el-table-column>
                    <el-table-column prop="number" label="金额" min-width="100" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="displayZh" label="展示状态" min-width="50" align="center"></el-table-column>
                    <el-table-column prop="updatedAt" label="更新时间" :formatter="formatter" min-width="90" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column label="操作" min-width="120" align="center">
                        <template #default="scope">
                            <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </template>
        <!-- 批量操作 -->
        <div class="block">
            <el-button type="danger" @click="handleBatchDelete">批量删除</el-button>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" v-model:current-page="currentPage" :page-size="query.size" :page-sizes="[10,20,30,50]" layout="total, sizes, prev, pager, next" :total=list.total></el-pagination>
        </div>
        <!-- 新增或编辑个人信息 -->
        <el-dialog :title="dialogTitle" v-model="dialogVisible">
            <el-form label-width="100px" :model="newQuery" ref="addQuery" :rules="rules">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="活动主题：" prop="title">
                            <el-input v-model="newQuery.title"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="活动描述：" prop="des">
                            <el-input v-model="newQuery.des" type="textarea" rows="5"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="基金数额：" prop="number">
                            <el-input v-model="newQuery.number" type="number">
                                <template #append>元</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="活动类型：" prop="type">
                            <el-radio-group v-model="newQuery.type">
                                <el-radio :label="1">回报社会</el-radio>
                                <el-radio :label="2">基金募集</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否展示：" prop="display">
                            <el-radio-group v-model="newQuery.display">
                                <el-radio :label="false">隐藏</el-radio>
                                <el-radio :label="true">展示</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="活动封面：" prop="cover">
                            <Upload :path ="newQuery.cover" v-model="newQuery.cover" @success="uploadSuccess"></Upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="dialogVisible = false" style="width: 100px;">取消</el-button>
                <el-button type="primary" @click="handleSure" :loading="loadingOne" style="width: 100px; margin-left: 40px;">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Upload from '../../src/components/common/Upload';
// import {fmt} from '@/assets/utils';
const Util = require('@/assets/utils');
import {
    getAllFund as get,
    updateInfoFund as updateInfo,
    createFund as create,
    deleteFund as del,
} from "@/api/api";
const code = 200
export default {
    name: "用户管理",
    data(){
        return{
            query: {
                page: 1,
                size: 10, 
                title: '',
                type: '',
                display: ''
            },
            addQuery: {
                id: '',
                title: '',
                des: '',
                type: '',
                number: '',
                cover: '',
                display: ''
            },
            rules:{
                title: [{required: true, message: '请输入主题', trigger: 'blur'}],
                type: [{required: true, message: '请选择类型', trigger: 'blur'}],
                des: [{required: true, message: '请输入描述', trigger: 'blur'}],
                number: [{required: true, message: '请输入金额', trigger: 'blur'}],
                cover: [{required: true, message: '请上传封面图', trigger: 'blur'}],
                display: [{required: true, message: '请选择展示状态', trigger: 'blur'}]
            },
            newQuery: {},
            list:{},
            currentPage: 1,
            selects: [],
            dialogTitle: '',
            dialogVisible: false,
            presentVisible: false,
            loadingOne: false,
            showshow: true,
        }
    },
    methods:{
        // 时间格式化
        formatter(row,column){
            return Util.fmt(row.updatedAt)
        },
        getSearchList(){
            this.loadingOne = true
            get(this.query).then(res =>{
                if(res.data.code == code){
                    this.list = res.data.data
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.msg
                    })
                }
                this.loadingOne = false
            }).catch(() =>{
                this.$message({
                    type: 'error',
                    message:"查询失败,请重试或者联系管理员！！"
                })
                this.loadingOne = false
            })
        },
        // 新增
        handleAdd(){
            this.dialogTitle = "新增"
            this.dialogVisible = true
            this.$nextTick(function(){
                this.$refs["addQuery"].resetFields()
                this.newQuery = Object.assign({}, this.addQuery)
            })
        },
        // 编辑
        handleEdit(index, row){
            this.dialogVisible = true
            this.dialogTitle = "编辑"
            this.$nextTick(function(){
                this.$refs['addQuery'].resetFields();
                this.newQuery = Object.assign({}, row)        
            })
        },
        // 删除
        handleDelete(index, row){
            this.requestDel(row.id)
        },
        // 新增或编辑之后的确定事件
        handleSure(){
            this.$refs["addQuery"].validate(valid =>{
                if(valid){
                    console.log(this.newQuery)
                    if(this.newQuery.id){
                        updateInfo(this.newQuery).then(res =>{
                            if(res.data.code == code){
                                this.$message({
                                    message: "更新成功",
                                    type: "success"
                                });
                                this.dialogVisible = false
                                this.getSearchList()
                            }else{
                                this.$message({
                                    message: res.data.msg,
                                    type: 'warning'
                                })
                            }
                        }).catch(err =>{
                            this.$message({
                                message: err.message,
                                type: 'warning'
                            })
                        })
                    }else{
                        create(this.newQuery).then(res =>{
                            if(res.data.code == code){
                                this.$message({
                                    message: "新增成功",
                                    type: "success"
                                });
                                this.dialogVisible = false
                                this.getSearchList()
                            }else{
                                this.$message({
                                    message: res.data.msg,
                                    type: 'warning'
                                })
                            }
                        }).catch(err =>{
                            this.$message({
                                message: err.message,
                                type: 'warning'
                            })
                        })
                    }
                }
            })
        },
        // 查询
        handleSearch(){
            this.getSearchList()
        },
        // 请求删除
        requestDel(ids){
            this.$confirm('此操作将永久删除该数据，是否继续？', '提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() =>{
                del({ids: ids}).then(res =>{
                    if(res.data.code == code){
                        this.$message({
                            type: "success",
                            message: res.data.msg
                        })
                        this.getSearchList()
                    }else{
                        this.$message({
                            type: "error",
                            message: res.data.msg
                        })
                    }
                }).catch(() =>{
                    this.$message({
                        type: 'error',
                        message:"删除失败,请重试或者联系管理员！！"
                    })
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        // 图片上传回传
        uploadSuccess(res){
            this.newQuery.cover = res
        },
        // 批量删除事件
        handleBatchDelete(){
            if(this.selects.length){
                let ids = this.selects.map(item => item.id).toString();
                this.requestDel(ids)
            }else{
                this.$message({
                    message: '请先勾选要删除的数据！！！',
                    type: 'warning'
                })
            }
        },
        // 修改size
        handleSizeChange(val){
            this.query.size = val
            this.getSearchList()
        },
        // 修改page
        handleCurrentChange(val){
            this.query.page = val
            this.getSearchList()
        },
        // 批量选择
        handleSelectionChange(val){
            this.selects = val
        }

    },
    mounted(){
        this.getSearchList()
    },
    components:{
        Upload
    }
}
</script>
<style lang="less">
.search .el-form-item{
    margin-right: 50px;
}
.block{
    margin-top: 10px;
}
.el-pagination{
    float: right;
}
.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px;
    height: 178px;
}
.avatar-uploader:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    /* height: 178px; */
    display: block;
}
.dialog-footer{
    text-align: center;
}
.dialog-footer .tips{
    line-height: 40px;
    color: red;
    /* background-color: yellow; */
    font-size: 10px;
    // text-align: right;
}
// 头部
.dialog-ckeditor-bg{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 500px;
    width: 1100px;
    height: 100%;
    width: 100%;
    background-color: white;
}
.ckeditor-header{
    height: 80px;
    width: 1100px;
    display: flex;
    margin: 0 auto;
}
.ckeditor-header-item{
    height: 100%;
}
.ckeditor-title{
    width: 100%;
    line-height: 80px;
    font-size: 20px;
    text-align: center;
    color: rgb(99, 99, 99);
}

.dialog-ckeditor{
    width: 1100px;
    margin: 0 auto;
}


// elementui 表格修改边框后下面还是有一条白线
.el-table::before {
    height: 0;  // 将高度修改为0
}

/* --------- EDITOR STYLES  ---------------------------------------------------------------------------------------- */

.editor__editable,
/* Classic build. */
main .ck-editor[role='application'] .ck.ck-content,
/* Decoupled document build. */
.ck.editor__editable[role='textbox'],
.ck.ck-editor__editable[role='textbox'],
/* Inline & Balloon build. */
.ck.editor[role='textbox'] {
	// width: 100%;
	// background: #fff;
    // background: rebeccapurple;
	font-size: 1em;
	line-height: 1.6em;
	min-height: var(--ck-sample-editor-min-height);
	padding: 1.5em 2em;
}
.ck-content{
    height: 1000px;
}

</style>